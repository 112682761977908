import React from 'react';

const SlackRedirectPage = () => {
  React.useEffect(() => {
    window.location.href = 'https://medium.com/bojagi';
  }, []);
  return <div />;
};

export default SlackRedirectPage;
